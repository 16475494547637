import React, { useEffect, useRef } from "react";

const Hero = () => {
   useEffect(() => {
      const canvas = document.getElementById("canvas1");
      const ctx = canvas.getContext("2d");
      canvas.width = 800;
      canvas.height = 500;
      let score = 0;
      let gameFrame = 0;
      ctx.font = "50px Georgia";

      // Mouse interactivity
      let canvasPosition = canvas.getBoundingClientRect();
      const mouse = {
         x: canvas.width / 2,
         y: canvas.height / 2,
         click: false,
      };
      canvas.addEventListener("mousemove", function (e) {
         mouse.click = true;
         mouse.x = e.x - canvasPosition.left;
         mouse.y = e.y - canvasPosition.top;
      });
      window.addEventListener("mouseup", function (e) {
         mouse.click = false;
      });

      // Player
      const playerLeft = new Image();
      playerLeft.src = "http://localhost:3000/asset/kk.png";
      const playerRight = new Image();
      playerRight.src = "http://localhost:3000/asset/kk.png";
      class Player {
         constructor() {
            this.x = canvas.width;
            this.y = canvas.height / 2;
            this.radius = 50;
            //this.height = 20;
            this.angle = 0;
            this.frameX = 0;
            this.frameY = 0;
            this.frame = 0;
            this.spriteWidth = 160;
            this.spriteHeight = 105;
         }
         update() {
            const dx = this.x - mouse.x;
            const dy = this.y - mouse.y;
            if (mouse.x != this.x) {
               this.x -= dx / 20;
               this.moving = true;
            }
            if (mouse.y != this.y) {
               this.y -= dy / 20;
               this.moving = true;
            }
            if (this.x < 0) this.x = 0;
            if (this.x > canvas.width) this.x = canvas.width;
            if (this.y < 50) this.y = 50;
            if (this.y > canvas.height) this.y = canvas.height;
            let theta = Math.atan2(dy, dx);
            this.angle = theta;
         }
         draw() {
            if (mouse.click) {
               ctx.lineWidth = 0.2;
               ctx.beginPath();
               ctx.moveTo(this.x, this.y);
               ctx.lineTo(mouse.x, mouse.y);
               ctx.stroke();
            }
            if (gameFrame % 10 == 0) {
               this.frame++;
               if (this.frame >= 12) this.frame = 0;
               if (this.frame == 3 || this.frame == 7 || this.frame == 11) {
                  this.frameX = 0;
               } else this.frameX++;
               if (this.frame < 3) {
                  this.frameY = 0;
               } else if (this.frame < 7) {
                  this.frameY = 1;
               } else if (this.frame < 11) {
                  this.frameY = 2;
               } else this.frameY = 0;
            }

            ctx.fillStyle = "black";
            ctx.save();
            ctx.translate(this.x, this.y);
            ctx.rotate(this.angle);
            //ctx.beginPath();
            //ctx.arc(0, 0, this.radius, 0, Math.PI * 360);
            //ctx.fill();
            if (this.x >= mouse.x) {
                // ctx.drawImage(
                //     playerLeft,
                //     this.frameX * this.spriteWidth,
                //     this.frameY * this.spriteHeight,
                //     this.spriteWidth,
                //     this.spriteHeight,
                //     0 - this.spriteWidth / 200,   // Adjust the position based on the image width
                //     0 - this.spriteHeight / 200,  // Adjust the position based on the image height
                //     this.spriteWidth,           // Set the destination width
                //     this.spriteHeight           // Set the destination height
                // );
            } else {
                // ctx.drawImage(
                //     playerLeft,
                //     this.frameX * this.spriteWidth,
                //     this.frameY * this.spriteHeight,
                //     this.spriteWidth,
                //     this.spriteHeight,
                //     0 - this.spriteWidth / 100,   // Adjust the position based on the image width
                //     0 - this.spriteHeight / 210,  // Adjust the position based on the image height
                //     this.spriteWidth,           // Set the destination width
                //     this.spriteHeight           // Set the destination height
                // );
            }
            ctx.restore();
         }
      }
      const player = new Player();

      // Bubbles
      const bubblesArray = [];
      const bubble = new Image();
      bubble.src = "https://i.ibb.co/ZX3thkw/pop2.png";
      class Bubble {
         constructor() {
            this.x = Math.random() * canvas.width;
            this.y = 0 - 50 - (Math.random() * canvas.height) / 2;
            this.radius = 50;
            this.speed = Math.random() * -5 + -1;
            this.distance();
            this.sound = Math.random() <= 0.5 ? "sound1" : "sound2";
            this.counted = false;
            this.frameX = 0;
            this.spriteWidth = 91;
            this.spriteHeight = 91;
            this.pop = false;
            this.counted = false;
         }
         update() {
            this.y -= this.speed;
            const dx = this.x - player.x;
            const dy = this.y - player.y;
            this.distance = Math.sqrt(dx * dx + dy * dy);
         }
         draw() {
            /*
        ctx.fillStyle = 'blue';
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fill();
        ctx.stroke();*/
            ctx.drawImage(
               bubble,
               this.frameX * this.spriteWidth,
               0,
               this.spriteWidth,
               this.spriteHeight,
               this.x - 68,
               this.y - 68,
               this.spriteWidth * 1.5,
               this.spriteHeight * 1.5
            );
         }
      }
      function handleBubbles() {
         for (let i = 0; i < bubblesArray.length; i++) {
            if (bubblesArray[i].y > canvas.height * 2) {
               bubblesArray.splice(i, 1);
            }
         }
         for (let i = 0; i < bubblesArray.length; i++) {
            if (
               bubblesArray[i].distance <
               bubblesArray[i].radius + player.radius
            ) {
               popAndRemove(i);
            }
         }
         for (let i = 0; i < bubblesArray.length; i++) {
            bubblesArray[i].update();
            bubblesArray[i].draw();
         }
         if (gameFrame % 50 == 0) {
            bubblesArray.push(new Bubble());
         }
      }
      function popAndRemove(i) {
         if (bubblesArray[i]) {
            if (!bubblesArray[i].counted) score++;
            bubblesArray[i].counted = true;
            bubblesArray[i].frameX++;
            if (bubblesArray[i].frameX > 7) bubblesArray[i].pop = true;
            if (bubblesArray[i].pop) bubblesArray.splice(i, 1);
            requestAnimationFrame(popAndRemove);
         }
      }

      /**** BUBBLE TEXT ***/
      let bubbleTextArray = [];
      let adjustX = -3;
      let adjustY = -3;
      ctx.fillStyle = "white";
      ctx.font = "17px Verdana";
      ctx.fillText("SEIMEN", 20, 42);
      //ctx.font = '19px Verdana';
      //ctx.fillText('TEXT', 36, 49);
      const textCoordinates = ctx.getImageData(0, 0, 100, 100);

      class Particle2 {
         constructor(x, y) {
            this.x = x;
            this.y = y;
            this.size = 7;
            this.baseX = this.x;
            this.baseY = this.y;
            this.density = Math.random() * 15 + 1;
            // this.distance
         }
         draw() {
            ctx.lineWidth = 3;
            ctx.strokeStyle = "rgba(34,147,214,1)";
            ctx.fillStyle = "rgba(255,255,255,1)";
            ctx.beginPath();
            if (this.distance < 50) {
               this.size = 14;
               ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
               ctx.stroke();
               ctx.closePath();
               ctx.beginPath();
               ctx.arc(this.x + 4, this.y - 4, this.size / 3, 0, Math.PI * 2);
               ctx.arc(this.x - 6, this.y - 6, this.size / 5, 0, Math.PI * 2);
            } else if (this.distance <= 80) {
               this.size = 8;
               ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
               ctx.stroke();
               ctx.closePath();
               ctx.beginPath();
               ctx.arc(this.x + 3, this.y - 3, this.size / 2.5, 0, Math.PI * 2);
               ctx.arc(this.x - 4, this.y - 4, this.size / 4.5, 0, Math.PI * 2);
            } else {
               this.size = 5;
               ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
               ctx.stroke();
               ctx.closePath();
               ctx.beginPath();
               ctx.arc(this.x + 1, this.y - 1, this.size / 3, 0, Math.PI * 2);
            }
            ctx.closePath();
            ctx.fill();
         }
         update() {
            let dx = player.x - this.x;
            let dy = player.y - this.y;
            let distance = Math.sqrt(dx * dx + dy * dy);
            this.distance = distance;
            let forceDirectionX = dx / distance;
            let forceDirectionY = dy / distance;
            let maxDistance = 100;
            let force = (maxDistance - distance) / maxDistance;
            let directionX = forceDirectionX * force * this.density;
            let directionY = forceDirectionY * force * this.density;

            if (distance < 100) {
               this.x -= directionX;
               this.y -= directionY;
            } else {
               if (this.x !== this.baseX) {
                  let dx = this.x - this.baseX;
                  this.x -= dx / 20;
               }
               if (this.y !== this.baseY) {
                  let dy = this.y - this.baseY;
                  this.y -= dy / 20;
               }
            }
         }
      }

      function init2() {
         bubbleTextArray = [];
         for (let y = 0, y2 = textCoordinates.height; y < y2; y++) {
            for (let x = 0, x2 = textCoordinates.width; x < x2; x++) {
               if (
                  textCoordinates.data[
                     y * 4 * textCoordinates.width + x * 4 + 3
                  ] > 128
               ) {
                  let positionX = x + adjustX;
                  let positionY = y + adjustY;
                  bubbleTextArray.push(
                     new Particle2(positionX * 8, positionY * 8)
                  );
               }
            }
         }
      }
      init2();
      console.log(bubbleTextArray);
      /** bubble text end **/

      // animation loop
      function animate() {
         ctx.clearRect(0, 0, canvas.width, canvas.height);

         for (let i = 0; i < bubbleTextArray.length; i++) {
            bubbleTextArray[i].draw();
            bubbleTextArray[i].update();
         }

         player.update();
         player.draw();

         gameFrame += 1;
         requestAnimationFrame(animate);
      }

      animate();

      window.addEventListener("resize", function () {
         canvasPosition = canvas.getBoundingClientRect();
         mouse.x = canvas.width / 2;
         mouse.y = canvas.height / 2;
      });
   }, []);
   return (
      <div className="container grid grid-cols-1 items-center">
         <canvas id="canvas1" className="mx-auto w-full mt-16"></canvas>
      </div>
   );
};

export default Hero;
